import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import PortableText from "../components/Blog/portableText";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import ModalVideo from "../components/Modal/ModalVideo";
import ButtonSolid from "../components/Button/ButtonSolid";
import ButtonWithUnderline from "../components/Button/ButtonWithUnderline";

export const query = graphql`
   query TeamMemberTemplateQuery($id: String!) {
      page: sanityTeamMember(id: { eq: $id }) {
         id
         name
         slug {
            current
         }
         seoTitle
         metaDescription
         headshot {
            asset {
               gatsbyImageData(layout: CONSTRAINED, width: 746)
               url
            }
         }
         title
         isRetired
         videoPosterImage {
            asset {
               gatsbyImageData(layout: CONSTRAINED, width: 124)
            }
         }
         videoSource
         videos {
            title
            videoPosterImage {
               asset {
                  gatsbyImageData(layout: CONSTRAINED, width: 124)
               }
            }
            videoSource
         }
         _rawBiography(resolveReferences: { maxDepth: 5 })
         _rawAdditionalDetails(resolveReferences: { maxDepth: 5 })
         professionalAssociationsMemberships {
            listItem
         }
         _rawHonors(resolveReferences: { maxDepth: 5 })
         awardsAccolades {
            listItem
         }
         awardBadges {
            asset {
               gatsbyImageData(layout: FIXED, width: 90)
            }
         }
         education {
            degree
            schoolCollege
            years
         }
         practiceAreas {
          ... on SanityPracticeAreas {
               title
               slug {
                  current
               }
            }
         }
         speakingEngagement {
            title
            _rawBody(resolveReferences: { maxDepth: 5 })
         }
         reviews {
            review
            name
         }
      }
      quotes: file(relativePath: { eq: "global/quotes.svg" }) {
         publicURL
      }
      quoteSolid: file(relativePath: { eq: "global/quote.svg" }) {
         publicURL
      }
   }
`;

const Template = (props) => {
   const [tabIndex, setTabIndex] = useState(0);
   const [videoSource, setVideoSource] = useState("");
   const [autoPlay, setAutoPlay] = useState(false);

   const handlePlayVideo = (source) => {
      setAutoPlay(true);
      setVideoSource(source);
   };

   //  useEffect(() => {}, [videoSource]);

   const StyledTabs = styled.div`
      .react-tabs__tab-list {
         ${tw`relative w-full flex space-x-4 2xs:space-x-6 lg:space-x-10 mb-12 px-8 pb-4 p-0 border-none after:w-full z-10 after:h-px after:bg-gray-100 after:absolute after:bottom-0 after:left-0`}
      }
      .react-tabs__tab {
         ${tw`text-primary-600/60 text-[13px] 2xs:text-sm md:text-base font-medium border-none pt-0 px-0 pb-4 md:pb-5 transition-colors duration-300 ease-linear after:w-0 after:h-1 after:bg-primary-600 after:absolute after:bottom-0.5 after:left-0 after:transition-all after:duration-300 after:ease-linear`}
      }
      .react-tabs__tab--selected {
         ${tw`text-primary-600 after:w-full bg-transparent`}
      }
   `;

   const StyledContent = styled.div`
      h2 {
         ${tw`text-4xl`}
      }
      h3 {
         ${tw`text-3xl`}
      }
   `;

   const { data, errors } = props;
   const page = data && data.page;

   return (
      <Layout>
         {errors && <SearchEngineOptimization title="GraphQL Error" />}
         {page && (
            <SearchEngineOptimization
               title={page.seoTitle}
               description={page.metaDescription}
               openGraphImage={page.headshot.asset.url}
               twitterOpenGraphImage={page.headshot.asset.url}
            />
         )}

         {errors && (
            <div className="container">
               <GraphQLErrorList errors={errors} />
            </div>
         )}

         {page && (
            <>
               <section className="relative pt-6 md:pt-16 pb-20 md:pb-32">
                  <div className="container !max-w-[81rem]">
                     <div className="grid md:grid-cols-12 gap-y-8 md:gap-x-10 lg:gap-x-18">
                        <div className="md:col-start-1 md:col-span-5 hidden md:block">
                           {page.headshot && <GatsbyImage image={page.headshot.asset.gatsbyImageData} alt={page.name} />}

                           {page.videoPosterImage && page.videoSource && (
                              <div className="pt-8 flex items-center">
                                 <button data-modal-open="modal-video" onClick={() => handlePlayVideo(page.videoSource)} className="relative">
                                    <GatsbyImage image={page.videoPosterImage.asset.gatsbyImageData} />
                                    <svg
                                       width="22"
                                       height="22"
                                       viewBox="0 0 22 22"
                                       fill="none"
                                       xmlns="http://www.w3.org/2000/svg"
                                       className="absolute inset-0 m-auto"
                                    >
                                       <path
                                          d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM14.3773 11.1617L9.25859 14.8859C9.23053 14.9061 9.19745 14.9181 9.16299 14.9207C9.12853 14.9233 9.09403 14.9164 9.06327 14.9006C9.03251 14.8849 9.00668 14.861 8.98863 14.8315C8.97057 14.802 8.96099 14.7681 8.96094 14.7336V7.28984C8.96082 7.25522 8.9703 7.22124 8.98832 7.19167C9.00633 7.1621 9.03219 7.1381 9.06301 7.12232C9.09383 7.10654 9.12842 7.09961 9.16295 7.10229C9.19747 7.10497 9.23057 7.11716 9.25859 7.1375L14.3773 10.8594C14.4015 10.8765 14.4213 10.8991 14.4349 10.9254C14.4485 10.9517 14.4556 10.9809 14.4556 11.0105C14.4556 11.0402 14.4485 11.0694 14.4349 11.0957C14.4213 11.122 14.4015 11.1446 14.3773 11.1617Z"
                                          fill="#D58B45"
                                       />
                                    </svg>
                                 </button>
                                 <div className="ml-4">
                                    <div className="text-sm text-secondary-50 font-bold">Meet {page.name}</div>
                                    <div className="text-sm text-gray-400">{page.title}</div>
                                 </div>
                              </div>
                           )}

                           {page.videos &&
                              page.videos.length > 0 &&
                              page.videos.map((item, i) => {
                                 return (
                                    <div className="mt-8 flex items-center">
                                       <button
                                          data-modal-open="modal-video"
                                          onClick={() => handlePlayVideo(item.videoSource)}
                                          className="relative"
                                          key={i}
                                       >
                                          <GatsbyImage image={item.videoPosterImage.asset.gatsbyImageData} />
                                          <svg
                                             width="22"
                                             height="22"
                                             viewBox="0 0 22 22"
                                             fill="none"
                                             xmlns="http://www.w3.org/2000/svg"
                                             className="absolute inset-0 m-auto"
                                          >
                                             <path
                                                d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM14.3773 11.1617L9.25859 14.8859C9.23053 14.9061 9.19745 14.9181 9.16299 14.9207C9.12853 14.9233 9.09403 14.9164 9.06327 14.9006C9.03251 14.8849 9.00668 14.861 8.98863 14.8315C8.97057 14.802 8.96099 14.7681 8.96094 14.7336V7.28984C8.96082 7.25522 8.9703 7.22124 8.98832 7.19167C9.00633 7.1621 9.03219 7.1381 9.06301 7.12232C9.09383 7.10654 9.12842 7.09961 9.16295 7.10229C9.19747 7.10497 9.23057 7.11716 9.25859 7.1375L14.3773 10.8594C14.4015 10.8765 14.4213 10.8991 14.4349 10.9254C14.4485 10.9517 14.4556 10.9809 14.4556 11.0105C14.4556 11.0402 14.4485 11.0694 14.4349 11.0957C14.4213 11.122 14.4015 11.1446 14.3773 11.1617Z"
                                                fill="#D58B45"
                                             />
                                          </svg>
                                       </button>
                                       <div className="ml-4">
                                          <div className="text-sm text-secondary-50 font-bold">{item.title}</div>
                                       </div>
                                    </div>
                                 );
                              })}
                        </div>
                        <div className="md:col-end-13 md:col-span-7">
                           <header className="mb-7 md:mb-10">
                              <h1 className="mb-3.5">{page.name}</h1>
                              <div className="tracking-wide uppercase">{page.title}</div>
                              <div className="tracking-wide uppercase font-bold">{page.isRetired ? "Retired":""}</div>
                           </header>

                           <div className="md:hidden mb-16">
                              {page.headshot && <GatsbyImage image={page.headshot.asset.gatsbyImageData} alt={page.name} />}

                              {page.videoPosterImage && page.videoSource && (
                                 <div className="pt-7 flex items-center">
                                    <button data-modal-open="modal-video" onClick={handlePlayVideo} className="relative">
                                       <GatsbyImage image={page.videoPosterImage.asset.gatsbyImageData} />
                                       <svg
                                          width="22"
                                          height="22"
                                          viewBox="0 0 22 22"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                          className="absolute inset-0 m-auto"
                                       >
                                          <path
                                             d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM14.3773 11.1617L9.25859 14.8859C9.23053 14.9061 9.19745 14.9181 9.16299 14.9207C9.12853 14.9233 9.09403 14.9164 9.06327 14.9006C9.03251 14.8849 9.00668 14.861 8.98863 14.8315C8.97057 14.802 8.96099 14.7681 8.96094 14.7336V7.28984C8.96082 7.25522 8.9703 7.22124 8.98832 7.19167C9.00633 7.1621 9.03219 7.1381 9.06301 7.12232C9.09383 7.10654 9.12842 7.09961 9.16295 7.10229C9.19747 7.10497 9.23057 7.11716 9.25859 7.1375L14.3773 10.8594C14.4015 10.8765 14.4213 10.8991 14.4349 10.9254C14.4485 10.9517 14.4556 10.9809 14.4556 11.0105C14.4556 11.0402 14.4485 11.0694 14.4349 11.0957C14.4213 11.122 14.4015 11.1446 14.3773 11.1617Z"
                                             fill="#D58B45"
                                          />
                                       </svg>
                                    </button>
                                    <div className="ml-4">
                                       <div className="text-sm text-secondary-50 font-bold">Meet {page.name}</div>
                                       <div className="text-sm text-gray-400">{page.title}</div>
                                    </div>
                                 </div>
                              )}

                              {page.videos &&
                                 page.videos.length > 0 &&
                                 page.videos.map((item, i) => {
                                    return (
                                       <div className="mt-8 flex items-center">
                                          <button
                                             data-modal-open="modal-video"
                                             onClick={() => handlePlayVideo(item.videoSource)}
                                             className="relative"
                                             key={i}
                                          >
                                             <GatsbyImage image={item.videoPosterImage.asset.gatsbyImageData} />
                                             <svg
                                                width="22"
                                                height="22"
                                                viewBox="0 0 22 22"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="absolute inset-0 m-auto"
                                             >
                                                <path
                                                   d="M11 0.5C5.20156 0.5 0.5 5.20156 0.5 11C0.5 16.7984 5.20156 21.5 11 21.5C16.7984 21.5 21.5 16.7984 21.5 11C21.5 5.20156 16.7984 0.5 11 0.5ZM14.3773 11.1617L9.25859 14.8859C9.23053 14.9061 9.19745 14.9181 9.16299 14.9207C9.12853 14.9233 9.09403 14.9164 9.06327 14.9006C9.03251 14.8849 9.00668 14.861 8.98863 14.8315C8.97057 14.802 8.96099 14.7681 8.96094 14.7336V7.28984C8.96082 7.25522 8.9703 7.22124 8.98832 7.19167C9.00633 7.1621 9.03219 7.1381 9.06301 7.12232C9.09383 7.10654 9.12842 7.09961 9.16295 7.10229C9.19747 7.10497 9.23057 7.11716 9.25859 7.1375L14.3773 10.8594C14.4015 10.8765 14.4213 10.8991 14.4349 10.9254C14.4485 10.9517 14.4556 10.9809 14.4556 11.0105C14.4556 11.0402 14.4485 11.0694 14.4349 11.0957C14.4213 11.122 14.4015 11.1446 14.3773 11.1617Z"
                                                   fill="#D58B45"
                                                />
                                             </svg>
                                          </button>
                                          <div className="ml-4">
                                             <div className="text-sm text-secondary-50 font-bold">{item.title}</div>
                                          </div>
                                       </div>
                                    );
                                 })}
                           </div>

                           {page.name === "Tiffanee Trujillo" ? (
                              <div>
                                 <div className="text-primary-800 text-xl md:text-3xl font-bold font-heading mb-6">
                                    In Memoriam, January 14, 1968 - May 16, 2022
                                 </div>
                                 <p>
                                    It is with deep sadness and a heavy heart we announce the passing of our Senior Paralegal, Tiffanee Trujillo.
                                    Tiffanee was a valued member of our team since joining the firm in 2017. She assisted our Family Law department
                                    and our clients with her vast experience, immense support, and sensitivity to the casework.
                                 </p>
                                 <p>
                                    Tiffanee is survived by her loving family, who, in her memory, would like to share some of the comments they have
                                    received from clients and opposing attorneys.{" "}
                                 </p>

                                 <div className="mt-12">
                                    <img src={data.quoteSolid.publicURL} alt="Quotes" width="46" className="mb-8" />
                                    <div className="space-y-16 md:space-y-20">
                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             She was always so sweet and easy to work with. I hope you and her family are able to find some peace.
                                          </q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             … I wanted to steal her from you – she was so good at her job and wonderful to talk to.
                                          </q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             Tiffanee was such a people person. She helped me out so much. I just wanted to cry all the time, and she
                                             had such sensitivity and caring toward me. She was a good listener and really cared.
                                          </q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             … She really held me up going through the divorce. She talked me away from the ledge many times.
                                          </q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             In the short time I have gotten to know her, I was impressed with how hard she worked and the passion she
                                             had for her job and clients. I'm sure her loss will be deeply felt, and I extend sincere condolences on
                                             the loss of your friend and colleague.
                                          </q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">She was always friendly, reassuring, and of great help when I talked with her.</q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             My deepest condolences to you, the firm, and her family for the loss of Tiffanee. To other clients and
                                             me, she was an amazing person, and she truly gave me incredible support. I am at a loss for words. I
                                             spoke to Tiffanee over the phone, and she shared and spoke to me a little about her life. She was truly
                                             an amazing woman. I am heartbroken I didn't get to meet her in person. She was an incredible person over
                                             the phone. I felt a strong connection with my situation; she understood and made me feel comfortable. I
                                             felt good being able to talk to Tiffanee; she knew what she was doing and made me feel good about this
                                             case! Glad I had the chance to thank her for the support she gave to me during this hard time in my case.
                                             Emotionally she helped me release some of my concerns and worries !! She will be truly missed !! And
                                             again, my deepest condolences to you and her family!!
                                          </q>
                                       </blockquote>

                                       <blockquote className="block">
                                          <q className="md:text-xl">
                                             There are no words that can be spoken to ease the pain of losing Tiffanee in her physical form. I
                                             recently learned of the book The invisible string by P. Karts. We are always connected to those we love
                                             the most.
                                          </q>
                                       </blockquote>
                                    </div>
                                 </div>
                              </div>
                           ) : (
                              <>
                                 <StyledTabs>
                                    <Tabs
                                       selectedIndex={tabIndex}
                                       onSelect={(index) => {
                                          setTabIndex(index);
                                          // clickHandler(index);
                                       }}
                                    >
                                       {/* <div className="relative"> */}
                                       {/* <button
                                                onClick={clickHandler}
                                                                          className={`flex justify-between md:hidden bg-primary-600 px-8 py-4 text-primary-50 hover:text-primary-50 font-bold no-underline w-full text-left ${
                                                  setActive ? "rounded-t-3xl" : "rounded-3xl"
                                                }`}
                                              >
                                                {setActiveText || "Support"}{" "}
                                                <i
                                                  className={`far fa-chevron-down text-xl transform ${
                                                    setActive ? "rotate-180" : "rotate-0"
                                                  }`}
                                                ></i>
                                              </button> */}

                                       {/* <div className={`${setActive}`}> */}
                                       {(page.professionalAssociationsMemberships.length > 0 ||
                                          page._rawHonors ||
                                          page.awardsAccolades.length > 0 ||
                                          page.awardBadges.length > 0 ||
                                          page.education.length > 0 ||
                                          page.practiceAreas.length > 0 ||
                                          page.reviews.length > 0) && (
                                          <TabList>
                                             <Tab>Biography</Tab>
                                             {(page.professionalAssociationsMemberships.length > 0 ||
                                                page._rawHonors ||
                                                page.awardsAccolades.length > 0 ||
                                                page.awardBadges.length > 0 ||
                                                page.education.length > 0) && <Tab>Credentials</Tab>}
                                             {page.practiceAreas.length > 0 && <Tab>Practice Areas</Tab>}
                                             {page.reviews.length > 0 && <Tab>Reviews</Tab>}
                                             {page.speakingEngagement.length > 0 && <Tab>Speaking Engagements</Tab>}
                                          </TabList>
                                       )}
                                       {/* </div> */}
                                       {/* </div> */}

                                       {(page._rawBiography || page._rawAdditionalDetails) && (
                                          <TabPanel>
                                             {page._rawBiography && (
                                                <div className="mb-12">
                                                   <h3 className="heading-four">Biography</h3>
                                                   <PortableText blocks={page._rawBiography} />
                                                </div>
                                             )}
                                             {page._rawAdditionalDetails && (
                                                <StyledContent>
                                                   <PortableText blocks={page._rawAdditionalDetails} />
                                                </StyledContent>
                                             )}
                                          </TabPanel>
                                       )}

                                       {(page.professionalAssociationsMemberships.length > 0 ||
                                          page._rawHonors ||
                                          page.awardsAccolades.length > 0 ||
                                          page.awardBadges.length > 0 ||
                                          page.education.length > 0) && (
                                          <TabPanel>
                                             {page.professionalAssociationsMemberships.length > 0 && (
                                                <div className="mb-12">
                                                   <h3 className="heading-four">Professional Associations & Memberships</h3>
                                                   <ul className="space-y-2">
                                                      {page.professionalAssociationsMemberships.map((item, i) => {
                                                         return (
                                                            <li
                                                               className="flex items-center before:min-w-[24px] before:h-0.5 before:bg-primary-600 before:block before:mr-6"
                                                               key={i}
                                                            >
                                                               {item.listItem}
                                                            </li>
                                                         );
                                                      })}
                                                   </ul>
                                                </div>
                                             )}
                                             {page._rawHonors && (
                                                <div className="mb-12">
                                                   <h3 className="heading-four">Honors</h3>
                                                   <PortableText blocks={page._rawHonors} />
                                                </div>
                                             )}

                                             {(page.awardsAccolades.length > 0 || page.awardBadges.length > 0) && (
                                                <div className="mb-12">
                                                   <h3 className="heading-four">Awards & Accolades</h3>
                                                   {page.awardsAccolades.length > 0 && (
                                                      <ul className="space-y-2 mb-8">
                                                         {page.awardsAccolades.map((item, i) => {
                                                            return (
                                                               <li
                                                                  className="flex items-center before:min-w-[24px] before:h-0.5 before:bg-primary-600 before:block before:mr-6"
                                                                  key={i}
                                                               >
                                                                  {item.listItem}
                                                               </li>
                                                            );
                                                         })}
                                                      </ul>
                                                   )}

                                                   {page.awardBadges.length > 0 && (
                                                      <div className="grid grid-cols-3 gap-x-4 items-center">
                                                         {page.awardBadges.map((item, i) => {
                                                            return (
                                                               <div key={i}>
                                                                  <GatsbyImage
                                                                     image={item.asset.gatsbyImageData}
                                                                     // className="w-[90px]"
                                                                  />
                                                               </div>
                                                            );
                                                         })}
                                                      </div>
                                                   )}
                                                </div>
                                             )}

                                             {page.education.length > 0 && (
                                                <div className="mb-12">
                                                   <h3 className="heading-four">Education</h3>
                                                   <div className="space-y-6">
                                                      {page.education.map((item, i) => {
                                                         return (
                                                            <div
                                                               className={`${
                                                                  page.education.length - 1 !== i &&
                                                                  "after:w-6 after:h-0.5 after:bg-primary-600 after:block after:mt-6"
                                                               }`}
                                                               key={i}
                                                            >
                                                               {item.degree && <div className="font-semibold">{item.degree}</div>}

                                                               {item.schoolCollege && <div className="">{item.schoolCollege}</div>}

                                                               {item.years && <div className="">{item.years}</div>}
                                                            </div>
                                                         );
                                                      })}
                                                   </div>
                                                </div>
                                             )}
                                          </TabPanel>
                                       )}

                                       {page.practiceAreas.length > 0 && (
                                          <TabPanel>
                                             <div className="mb-12">
                                                <h3 className="heading-four">Practice Areas</h3>
                                                <ul className=" grid md:grid-cols-2 md:gap-x-8 gap-y-5 max-w-[375px]">
                                                   {page.practiceAreas.map((item, i) => {
                                                      return (
                                                         <li key={i} className="">
                                                            <ButtonWithUnderline href={`/${item.slug.current}/`} text={item.title} />
                                                         </li>
                                                      );
                                                   })}
                                                </ul>
                                             </div>
                                          </TabPanel>
                                       )}

                                       {page.reviews.length > 0 && (
                                          <TabPanel>
                                             <div className="mb-12">
                                                <img src={data.quotes.publicURL} alt="Quotes" width="24" className="mb-8" />
                                                {page.reviews.map((item, i) => {
                                                   return (
                                                      <blockquote
                                                         key={i}
                                                         className={`block after:mt-5 after:w-6 after:h-0.5 after:bg-primary-600 after:inline-block ${
                                                            i !== page.reviews.length - 1 && "mb-5"
                                                         } `}
                                                      >
                                                         <q className="block mb-4 before:hidden">{item.review}</q>
                                                         <footer>
                                                            <cite className="not-italic font-bold">{item.name}</cite>
                                                         </footer>
                                                      </blockquote>
                                                   );
                                                })}
                                             </div>
                                          </TabPanel>
                                       )}

                                       {page.speakingEngagement.length > 0 && (
                                          <TabPanel>
                                             <div className="mb-12">
                                                <h3 className="heading-four">Speaking Engagement</h3>
                                                <ul className="space-y-2">
                                                   {page.speakingEngagement.map((item, i) => {
                                                      return (
                                                         <li
                                                            className="flex items-start before:min-w-[24px] before:h-0.5 before:bg-primary-600 before:block before:mr-6 before:mt-3 speaking-engagement-item"
                                                            key={i}
                                                         >
                                                           <PortableText blocks={item._rawBody} />
                                                         </li>
                                                      );
                                                   })}
                                                </ul>
                                             </div>
                                          </TabPanel>
                                       )}
                                    </Tabs>
                                 </StyledTabs>
                                 <ButtonSolid modal="modal-contact" text="Contact Us" className="w-full md:w-auto" />
                              </>
                           )}
                        </div>
                     </div>
                  </div>
               </section>

               <CallToAction />
               <ModalVideo autoPlay={autoPlay} videoSource={videoSource} />
            </>
         )}
      </Layout>
   );
};

export default Template;
